import React from 'react'

function SkillsPercent({percent}) {

  const innerPercentageStyle = {
    width:percent+"%",
  }

  return (
    <div className="percentage-container position-relative d-block w-100">
      <div style={innerPercentageStyle} className="percentage-inner position-absolute d-block top-0 start-0 d-block"></div>
    </div>
  )
}

export default SkillsPercent