import React from 'react'
import Nav from 'react-bootstrap/Nav';

function ProfileSocialIcon() {
  return (
    <div id="profile-social-icon" className="mt-5">
      <div className="justify-content-end d-flex">
        <Nav.Link href="https://www.linkedin.com/in/tris-star-388599199/" target="_blank" className="me-1">
          <img alt="linkedin" width="42px" className="img-fluid" src={process.env.PUBLIC_URL + '/assets/images/social-icon/linkedin-icon.png'} />
        </Nav.Link>
        <Nav.Link href="https://www.facebook.com/trisstar.salva" target="_blank" className="me-1">
          <img alt="facebook" width="42px" className="img-fluid" src={process.env.PUBLIC_URL + '/assets/images/social-icon/fb-icon.png'} />
        </Nav.Link>        
        <Nav.Link href="https://twitter.com/Trisstar4/with_replies" target="_blank" className="me-1">
          <img alt="twitter" width="42px" className="img-fluid" src={process.env.PUBLIC_URL + '/assets/images/social-icon/twitter-icon.png'} />
        </Nav.Link>
        <Nav.Link href="https://www.facebook.com/" target="_blank" className="me-1">
          <img alt="intagram" width="42px" className="img-fluid" src={process.env.PUBLIC_URL + '/assets/images/social-icon/intagram-icon.png'} />
        </Nav.Link>        
      </div>      
    </div>
  )
}

export default ProfileSocialIcon