import React from 'react';
import Typewriter from 'typewriter-effect';

function ProfileSummary() {
  return (
    <div id="profile-summary" className="mt-5">
      <div className="comment-top-tag">
        <span>Hi There!</span>
      </div>
      <h1>I am Tris Star Salva</h1>
      <div className="slogan-profile">
        <h2>
          <Typewriter
            options={{
              strings: ["A Web Developer.", "A CMS Developer.", "I Love to Develop."],
              autoStart: true,
              cursor: "_",
              loop: true,
            }}
          />
        </h2>
      </div>
      <div id="profile-content" className="mt-5">
        A Full Stack Developer that can Develop, Manage and Learn new Programming Language, Framework, Content Management System, Tools for the task and goals needed by all types of Companies.
      </div>
    </div>
  )
}

export default ProfileSummary