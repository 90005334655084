import React from 'react'
import MainMenu from '../MainMenu'

function Header() {
  return (
    <header>
      <MainMenu/>
    </header>    
  )
}

export default Header