import React from 'react'

function AboutContent() {
  return (
    <div id="about-wrapper">
      <h4 className="about-title">About Me</h4>
      <div className="about-content">
        <p>A Web Developer with 5+ year of experience in developing, debugging, and testing websites, this also includes training clients how to update contents in their web page. Able to effectively self-manage during independent projects, as well as collaborate in a team setting. With a Bachelor of Science - BS focused in Computer Programming from AMA Computer College.</p>
      </div>
      <h4 className="about-title">Contact Details</h4>
      <div className="about-content">
        <div className="about-name">Tris Star Salva</div>
        <div className="about-address">Block 8 Lot 10 Section 6 Pabahay 2000 <br /> Phase 1 Muzon San Jose Delmonte <br /> Bulacan City </div>
        <div className="about-number">09292081150</div>
        <div className="about-email">trisstarsalva@awesomestardev.com</div>
      </div>
    </div>
  )
}

export default AboutContent