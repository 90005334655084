import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PortfolioTeaser from './PortfolioTeaser';

function PortfolioContent() {
  const dataPortfolio = [
    {
      id:'beastchoice',
      title:'Beastchoice',
      link:'bestchoice.com.ph',
      ts_image:'portfolio/beastchoice/ts-beastchoice.jpg',
      gallery:[ 
        { 
          src:'/assets/images/portfolio/beastchoice/beastchoice-home.png',
        }
      ],
    },
    {
      id:'cem',
      title:'CEM',
      link:'https://www.cem-inc.org.ph',
      ts_image:'portfolio/cem/ts-cem.jpg',
      gallery:[
        { 
          src:'/assets/images/portfolio/cem/cem-home.png',
        }
      ],
    },
    {
      id:'donbosco',
      title:'Donbosco',
      link:'https://www.donboscopress.ph',
      ts_image:'portfolio/donbosco/ts-donbosco.jpg',
      gallery:[
        { 
          src:'/assets/images/portfolio/donbosco/donbosco-home.png',
        }
      ],
    },
    {
      id:'exclure',
      title:'Exclure',
      link:'https://www.exclurepestcontrol.com',
      ts_image:'portfolio/exclure/ts-exclure.jpg',
      gallery:[
        { 
          src:'/assets/images/portfolio/exclure/exclure-home.png',
        }
      ],
    },
    {
      id:'fga',
      title:'First Gourmet Academy',
      link:'https://www.firstgourmetacademy.com',
      ts_image:'portfolio/fga/ts-fga.jpg',
      gallery:[
        { 
          src:'/assets/images/portfolio/fga/fga-home.png',
        }
      ],
    },
    {
      id:'hino',
      title:'Hino',
      link:'https://hino.com.ph',
      ts_image:'portfolio/hino/ts-hino.jpg',
      gallery:[
        { 
          src:'/assets/images/portfolio/hino/hino-home.png',
        }
      ],
    },
    {
      id:'hotelturista',
      title:'Hotel Turista',
      link:'',
      ts_image:'portfolio/hotelturista/ts-hotelturista.jpg',
      gallery:[
        { 
          src:'/assets/images/portfolio/hotelturista/hotelturista-home.png',
        }
      ],
    },
    {
      id:'marubeni',
      title:'Marubeni',
      link:'https://marubeniphil.com',
      ts_image:'portfolio/marubeni/ts-marubeni.jpg',
      gallery:[
        { 
          src:'/assets/images/portfolio/marubeni/marubeni-home.png',
        }
      ],
    },
    {
      id:'opmc',
      title:'OPMC',
      link:'https://www.opmc.ph',
      ts_image:'portfolio/opmc/ts-opmc.jpg',
      gallery:[
        { 
          src:'/assets/images/portfolio/opmc/opmc-home.png',
        }
      ],
    },
    {
      id:'pesin',
      title:'Pesin',
      link:'https://pesin.com.ph',
      ts_image:'portfolio/pesin/ts-pesin.jpg',
      gallery:[
        { 
          src:'/assets/images/portfolio/pesin/pesin-home.png',
        }
      ],
    },
    {
      id:'primark',
      title:'Primark',
      link:'https://primark.com.ph',
      ts_image:'portfolio/primark/ts-primark.jpg',
      gallery:[
        { 
          src:'/assets/images/portfolio/primark/primark-home.png',
        }
      ],
    },
    {
      id:'sexybeast',
      title:'Sexybeast',
      link:'https://sexybeastlife.com',
      ts_image:'portfolio/sexybeast/ts-sexybeast.jpg',
      gallery:[
        { 
          src:'/assets/images/portfolio/sexybeast/sexybeast-home.png',
        }
      ],
    },
    {
      id:'tms',
      title:'Transport Management System',
      link:'',
      ts_image:'portfolio/tms/ts-tms.jpg',
      gallery:[
        { 
          src:'/assets/images/portfolio/tms/tms-home.png',
        }
      ],
    },
    {
      id:'yakult',
      title:'Yakult',
      link:'',
      ts_image:'portfolio/yakult/ts-yakult.jpg',
      gallery:[
        { 
          src:'/assets/images/portfolio/yakult/yakult-home.png',
        }
      ],
    },
  ];
  return (
    <Row>
      {
        dataPortfolio.map((portfolio,index)=>(
          <Col key={index} md={6} className="mb-4">
            <PortfolioTeaser portfolio={portfolio} />
          </Col>
        ))
      }
    </Row>
  )
}

export default PortfolioContent