import './Style/App.scss'
import "yet-another-react-lightbox/styles.css";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import MainPage from './Page/MainPage'

function App() {
  return (
    <BrowserRouter>
      <Routes>        
        <Route path="" index element={<MainPage />}/>
      </Routes>    
    </BrowserRouter>
  );
}

export default App;
