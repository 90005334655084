import React from 'react'
import Container from 'react-bootstrap/Container';
import Content from './Content'

function SkillsRegion() {
  return (
    <div id="skills-region" className="w-100 pt-5 pb-5">
      <Container>
        <h1 className="mb-4">Skills</h1>
        <Content/>
      </Container>
    </div>
  )
}

export default SkillsRegion