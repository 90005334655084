import React from 'react'

function ProfileImage() {
  return (
    <div className="profile-image text-center">
      <img alt="profile" className="img-fluid w-100" src={process.env.PUBLIC_URL + '/assets/images/profile-image.jpg'} />
    </div>        
  )
}

export default ProfileImage