import React, {useEffect, useState} from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';


function MainMenu() {

  const [menuType, setMenuType] = useState();
  const [stickyTop, setStickyTop] = useState();
  const [menuPosition, setMenuPosition] = useState();
  const [bugerMenu, setBurgerMenu] = useState(false);
  
  useEffect(()=>{
    loadEvent();
    window.addEventListener('scroll', scrollEvent);
    window.addEventListener('resize', resizeEvent);
    
    return ()=> {
      window.removeEventListener('scroll', scrollEvent);      
      window.addEventListener('resize', resizeEvent);
    }
  });

  const scrollEvent = (()=>{
    addSticky();
  });

  const loadEvent = (()=>{
    addSticky();
    showBurgerMenu();
    showMenuType();
  });

  const resizeEvent = (()=>{
    showBurgerMenu();
    showMenuType();
  });

  const showMenuType = (()=>{
    if(window.innerWidth < 768){
      setMenuType('mobile-menu');
    } else {
      setMenuType('desktop-menu');
    }
  });

  const showBurgerMenu = (()=> {
    if(window.innerWidth < 768){
      setBurgerMenu(true);
    } else {
      setBurgerMenu(false);
    }
  });

  const addSticky = (()=> {
    if(window.pageYOffset >= 100){
      setStickyTop('fixed-top menu-sticky');
      setMenuPosition('justify-content-center');
    }
    if(window.pageYOffset < 100){
      setStickyTop('');
      setMenuPosition('justify-content-end');
    }
  });

  return (
    <div id="main-menu" className={`${menuType} ${stickyTop}`}>
      <Navbar key="md" expand="md">
        <Container fluid>
          { bugerMenu &&
            <div className="menu-trigger-button d-flex justify-content-end w-100 mt-3 mb-3">
              <Navbar.Toggle aria-controls="main-navbar-nav" />
            </div>  
          }          
          <Navbar.Collapse id="main-navbar-nav">
            <Nav className={`${menuPosition} flex-grow-1 pe-3`}>
              <Nav.Link href="#home-region">Home</Nav.Link>
              <Nav.Link href="#about-region">About</Nav.Link>
              <Nav.Link href="#skills-region">Skills</Nav.Link>
              <Nav.Link href="#portfolio-region">Portfolio</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

export default MainMenu