import React from 'react'
import Container from 'react-bootstrap/Container';
import Content from './Content';

function PortfolioRegion() {
  return (    
    <div id="portfolio-region" className="w-100 pt-5 pb-5">
      <Container>
        <h2 className='text-center mb-5'>My Portfolio</h2>
        <Content/>        
      </Container>
    </div>
  )
}

export default PortfolioRegion