import React from 'react';
import Container from 'react-bootstrap/Container';
import Content from './Content';

function AboutRegion() {
  return (
    <div id="about-region" className="w-100 pt-5 pb-5">
      <Container>
        <Content/>        
      </Container>
    </div>
  )
}

export default AboutRegion