import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProfileImage from '../../../../Content/ProfileImage';
import ProfileSummary from '../../../../Content/ProfileSummary';
import ProfileSocialIcon from '../../../../Content/ProfileSocialIcon';

function Content() {
  return (
    <Row className="pb-5">
      <Col md={6}>
        <ProfileImage />
      </Col>
      <Col md={6}>
        <div id="profile-wrapper">
          <ProfileSummary />
          <ProfileSocialIcon />
        </div>        
      </Col>
    </Row>    
  )
}

export default Content