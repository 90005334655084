import React from 'react'
import ThemeProvider from 'react-bootstrap/ThemeProvider';
import HomeRegion from '../../Components/MainPage/HomeRegion'
import AboutRegion from '../../Components/MainPage/AboutRegion'
import SkillsRegion from '../../Components/MainPage/SkillsRegion';
import PortfolioRegion from '../../Components/MainPage/PortfolioRegion';


function MainPage() {
  return (
  <div id="main-page-container">
    <ThemeProvider
    breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
    minBreakpoint="xxs"
    > 
      <HomeRegion />
      <AboutRegion />
      <SkillsRegion />
      <PortfolioRegion />
    </ThemeProvider>
  </div>  
  )
}

export default MainPage