import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AboutContent from '../../../../Content/AboutContent';
import AboutImage from '../../../../Content/AboutImage';

function Content() {
  return (
    <Row>
      <Col md={7}>
        <AboutContent />
      </Col>
      <Col md={5}>
        <AboutImage />
      </Col>
    </Row>
  )
}

export default Content