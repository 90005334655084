import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SkillsPercent from './SkillsPercent';

function SkillsContent() {

  const dataSkills = [
    {
      title:'PHP',
      percent:88,      
    },
    {
      title:'HTML5',
      percent:100,      
    },
    {
      title:'CSS/CSS3',
      percent:90,      
    },
    {
      title:'Bootstrap',
      percent:95,      
    },
    {
      title:'Javascript',
      percent:83,      
    },    
    {
      title:'Jquery',
      percent:88,
    },    
    {
      title:'MYSQL',
      percent:85,      
    },
    {
      title:'Drupal',
      percent:95,      
    },
    {
      title:'Wordpress',
      percent:80,      
    },
    {
      title:'Ember Js',
      percent:93,
    },
    {
      title:'React Js',
      percent:89,
    },
    {
      title:'Rest API Development',
      percent:80,
    },
    {
      title:'Photoshop',
      percent:83,
    },
  ];
  return (
    <Row>
      {
        dataSkills.map((skills, index) => (
          <Col key={index} md={4} lg={3} className="mb-3">
            <div className="skills-container">
              <h6 className="skills-name d-flex">
                <span className="w-100">{skills.title}</span>
                <span className="skill-percen-tag flex-shrink-1">{skills.percent}%</span>
              </h6>
              <SkillsPercent percent={skills.percent} />
            </div> 
          </Col>
        ))
      }
      
    </Row>
  )
}

export default SkillsContent