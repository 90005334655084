import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from '../../MainPage/Header'
import Content from './Content';

function Region1() {
  return (
    <div id="home-region" className="w-100">
      <Container>
        <Row>
          <Col>
            <Header/>
            <Content/>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Region1