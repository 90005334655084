import React, { useState, useEffect, useRef } from 'react'
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";


function PortfolioTeaser({portfolio}) {

  const renderUseEffect = useRef(false);
  const [gallery, setGallery] = useState(portfolio.gallery);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(()=>{
    console.log('test');
    if (!renderUseEffect.current) { 
      renderUseEffect.current = true;
      setGallery(portfolio.gallery);
    }     
  },[portfolio]);
  
  return (
    <>
      <div className="portfolio-wrapper border position-relative shadow" onClick={() => setIsOpen(true)}>
        <div className="portfolio-ts-image text-center">
          <img alt="portfolio"  className="img-fluid w-100" src={process.env.PUBLIC_URL + '/assets/images/' + portfolio.ts_image} />
        </div>
        <div className="portfolio-body text-center w-100 position-absolute top-50 start-50 translate-middle">
          <h4 className="portfolio-title">{portfolio.title}</h4>
          <div className="portfolio-handler"></div>
        </div>
      </div>
      <Lightbox
        open={isOpen}
        close={() => setIsOpen(false)}
        slides={gallery}
        plugins={[Zoom, Fullscreen]}
      />
    </>
    
  )
}

export default PortfolioTeaser