import React from 'react'

function AboutImage() {
  return (
    <div className="about-image text-center">
      <img alt="about" width="200px" className="img-fluid rounded-circle" src={process.env.PUBLIC_URL + '/assets/images/about-image.jpg'} />
    </div>      
  )
}

export default AboutImage